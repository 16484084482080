import {
    ArrayContainer,
    ArrayEntryParams,
    Button,
    CheckCircleOutlineIcon,
    Chip,
    CircularProgress,
    ConfirmationDialog,
    getSubscriptionStatusText,
    HighlightOffIcon,
    Paper,
    ProLicense,
    Subscription,
    TextField,
    Tooltip,
    Typography,
    useDebounceValue,
    useFireCMSBackend,
    useLicensesForUserController,
} from "@firecms/cloud";
import { useEffect, useState } from "react";
import { generateApiKey } from "./api_keys";

export function ProLicenseView({
                                   license,
                                   onMissingSubscriptionClick
                               }: {
    license: ProLicense,
    onMissingSubscriptionClick: (license: ProLicense) => void
}) {

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const {
        listenSubscriptionsForLicense,
        updateLicense
    } = useLicensesForUserController();

    useEffect(() => {
        return listenSubscriptionsForLicense(
            license.id,
            setSubscriptions,
            (error) => console.error(error)
        );
    }, [license.id, listenSubscriptionsForLicense]);

    const statusText = getSubscriptionStatusText(license.status);

    const [projectIds, setProjectIds] = useState<string[]>(license.firebase_project_ids);
    const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);

    const debouncedProjectIds = useDebounceValue(projectIds, 200);
    useEffect(() => {
        updateLicense(license.id, { firebase_project_ids: debouncedProjectIds });
    }, [debouncedProjectIds]);

    const hasActiveSubscriptions = subscriptions.some(subscription => subscription.status === "active");

    return (
        <Paper className="py-6 px-4">
            <div className="flex flex-col sm:flex-row sm:justify-between mt-2">

                <div className="mb-4">
                    <Typography variant="h5">
                        License Details
                    </Typography>
                    <Typography variant="body2" color="secondary">{license.id}</Typography>
                    <Chip
                        size={"smallest"}
                        colorScheme={statusText === "Active" ? "greenDark" : "orangeDark"}>
                        {statusText}
                    </Chip>

                    <Tooltip
                        title={hasActiveSubscriptions ? "You can only archive licenses with no active subscriptions" : undefined}>
                        {license.status === "canceled" && <Button variant={"text"}
                                                                  className={"ml-2"}
                                                                  size={"small"}
                                                                  color={"text"}
                                                                  disabled={hasActiveSubscriptions}
                                                                  onClick={() => {
                                                                      setArchiveDialogOpen(true);
                                                                  }}>
                            Archive this license
                        </Button>}
                    </Tooltip>
                </div>
                <div className="flex flex-col space-y-4 mb-8">

                    <table className={"w-fit"}>
                        <tbody>
                        <tr>
                            <td><Typography variant="body1">Licensed Users</Typography></td>
                            <td className={"px-4"}><Typography variant="subtitle2">{license.licensed_users}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td><Typography variant="body1">Created At</Typography></td>
                            <td className={"px-4"}><Typography
                                variant="subtitle2">{license.created_at?.toLocaleDateString()}</Typography></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {subscriptions.length > 0 && (
                <>
                    {subscriptions.map((subscription) => (
                        <LicenseSubscriptionView
                            key={subscription.id}
                            subscription={subscription}
                        />
                    ))}
                </>
            )}

            {subscriptions.length === 0 && (
                <>
                    <Typography variant="body2" color={"disabled"} className={"mb-2"}>
                        No active subscriptions
                    </Typography>
                    <Button variant={"outlined"}
                            onClick={() => {
                                onMissingSubscriptionClick(license);
                            }}>
                        Subscribe
                    </Button>

                </>
            )}

            {/* API key generation */}
            <div className="flex flex-col gap-2 mt-8">
                <Typography variant="subtitle1">API Key</Typography>
                <Typography variant="body2">
                    You need to pass this key to your <code>FireCMS</code> component to enable the PRO features.
                </Typography>
                <div className={"flex flex-row gap-2"}>
                    <code className="flex-grow p-2 mb-4 bg-gray-50 dark:bg-gray-900 rounded">
                        {license.api_key}
                    </code>
                    <Button variant={"text"}
                            onClick={() => {
                                updateLicense(license.id, { api_key: generateApiKey() });
                            }}>
                        Regenerate
                    </Button>
                </div>
            </div>

            {/*    Related project IDs*/}
            <div className="flex flex-col gap-2 mt-8">
                <Typography variant="subtitle1">Related Firebase Projects</Typography>
                <Typography variant="body2">
                    These are the Firebase projects associated with this license. You can use your license
                    in any of the projects defined here.
                </Typography>
                <ProjectsEditView projectIds={projectIds}
                                  setProjectIds={setProjectIds}/>

            </div>

            <ConfirmationDialog open={archiveDialogOpen}
                                onAccept={() => {
                                    updateLicense(license.id, { archived: true });
                                    setArchiveDialogOpen(false);
                                }}
                                onCancel={() => setArchiveDialogOpen(false)}
                                title={"Archive License?"}
                                body={
                                    <Typography variant={"body2"}>
                                        This action will archive the license and remove it from the active licenses
                                        list.
                                        You might continue using the related projects while a valid subscription is
                                        active.
                                    </Typography>}
            />

        </Paper>
    );
}

function LicenseSubscriptionView({
                                     subscription,
                                 }: {
    subscription: Subscription;
}) {
    const { projectsApi } = useFireCMSBackend();
    const statusText = getSubscriptionStatusText(subscription.status);

    const [stripeCancelPortalUrl, setStripeCancelPortalUrl] = useState<string>();
    const [stripeUpdatePortalUrl, setStripeUpdatePortalUrl] = useState<string>();
    const [stripeCancelPortalUrlLoading, setStripeCancelPortalUrlLoading] = useState<boolean>(false);
    const [stripeUpdatePortalUrlLoading, setStripeUpdatePortalUrlLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!subscription.canceled_at) {
            setStripeCancelPortalUrlLoading(true);
            projectsApi
                .getStripeCancelLinkForSubscription(subscription.id)
                .then(setStripeCancelPortalUrl)
                .catch(console.error)
                .finally(() => setStripeCancelPortalUrlLoading(false));
        }
    }, [subscription.id, subscription.canceled_at, projectsApi]);

    useEffect(() => {
        setStripeUpdatePortalUrlLoading(true);
        projectsApi
            .getStripeUpdateLinkForSubscription(subscription.id)
            .then(setStripeUpdatePortalUrl)
            .catch(console.error)
            .finally(() => setStripeUpdatePortalUrlLoading(false));
    }, [subscription.id, projectsApi]);

    const isActive = subscription.status === "active";

    const StatusIcon = isActive ? CheckCircleOutlineIcon : HighlightOffIcon;

    return (
        <Paper className="p-4">
            <div className="flex items-start space-x-4">
                <StatusIcon
                    className={isActive ? "text-green-500" : "text-red-500"}
                    style={{ fontSize: 32 }}
                />
                <div className="flex-1 pl-2">
                    <Typography variant="subtitle2" className={"flex flex-row gap-2"}>
                        Subscription
                        <Chip
                            size={"smallest"}
                            colorScheme={statusText === "Active" ? "greenDark" : "orangeDark"}>
                            {statusText}
                        </Chip>
                    </Typography>
                    <Typography variant="body2" color="secondary">
                        {subscription.id}
                    </Typography>
                    {subscription.cancel_at && (
                        <Typography variant="body2" className="text-red-500 dark:text-red-500 mt-1">
                            Active until{" "}
                            {subscription.cancel_at.toDate().toLocaleDateString()}
                        </Typography>
                    )}
                </div>
                <div className="w-full mt-4 flex gap-2 justify-end">

                    {isActive ? (
                        <>
                            {!subscription.canceled_at && <Button
                                component="a"
                                size="small"
                                variant={"text"}
                                href={stripeCancelPortalUrl}
                                target="_blank"
                                rel="noreferrer"
                                color="text"
                            >
                                {stripeCancelPortalUrlLoading ? <CircularProgress size={"small"}/> : "Cancel"}
                            </Button>}
                            <Button
                                component="a"
                                variant={"outlined"}
                                size="small"
                                href={stripeUpdatePortalUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {stripeUpdatePortalUrlLoading
                                    ? <CircularProgress size={"small"}/>
                                    : (subscription.canceled_at ? "Renew" : "Update user count")}
                            </Button>
                        </>
                    ) : (
                        <Button
                            component="a"
                            size="small"
                            href={stripeCancelPortalUrl}
                            target="_blank"
                            rel="noreferrer"
                            color="primary"
                        >
                            {stripeCancelPortalUrlLoading ? <CircularProgress size={"small"}/> : "Renew"}
                        </Button>
                    )}
                </div>
            </div>

        </Paper>
    );
}

function ProjectsEditView({
                              projectIds,
                              setProjectIds,
                          }: {
    projectIds: string[];
    setProjectIds: (projectIds: string[]) => void;
}) {

    const buildEntry = ({ index }: ArrayEntryParams) => {
        const projectId = projectIds[index];
        return <TextField
            type="text"
            size={"smallest"}
            value={projectId}
            onChange={(e) => {
                const value = e.target.value;
                const newProjectIds = [...projectIds];
                newProjectIds[index] = value;
                setProjectIds(newProjectIds);
            }}
            placeholder="Project ID"
        />;
    }

    return (
        <ArrayContainer droppableId={"project-ids"}
                        value={projectIds}
                        buildEntry={buildEntry}
                        size={"medium"}
                        includeAddButton={true}
                        newDefaultEntry={""}
                        onValueChange={(value) => setProjectIds(value)}
                        addLabel={"Add project"}/>
    );
}

