import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/browser";

import { FirebaseApp } from "@firebase/app";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import "@fontsource/jetbrains-mono";
import "typeface-rubik";

import { CircularProgressCenter, NotFoundPage, SnackbarProvider } from "@firecms/core";

import { CenteredView } from "@firecms/ui";
import { FireCMSBackend, FireCMSBackEndProvider, FireCMSCloudLoginView, useBuildFireCMSBackend } from "@firecms/cloud";
import { NewFireCMSProjectStart } from "./routes/NewFireCMSProjectStart";
import { SassDebugView } from "./routes/SassDebugView";
import { NewGoogleCloudProjectFlow } from "./routes/NewGoogleCloudProjectFlow";
import { SaasCMSAppClient } from "./routes/SaasCMSAppClient";
import { FireCMSDoctorProjectPage } from "./routes/FireCMSDoctorProjectPage";
import { SaasMainPage } from "./routes/SaasMainPage";
import { SaasScaffold } from "./components/SaasScaffold";
import { FireCMSDoctorListPage } from "./routes/FireCMSDoctorListPage";
import { useSaasAnalytics } from "./components/SaasAnalyticsProvider";
import { UserSubscriptions } from "./routes/UserSubscriptions";
import { FireCMSCLIView } from "./cli/cli";
import { SaasClientContext } from "./useSaasClientController";

export type SaasClientController = {
    selectProject: (projectId?: string) => void;
    onNewProject: () => void;
    goToDoctor: (projectId: string) => void;
    goToSubscriptions: () => void;
    fireCMSBackend: FireCMSBackend;
}

const adminPaths = [
    "/debug",
    "/gcp",
    "/doctor"
];

const hideProjectSelectorPaths = [
    "/debug",
    "/gcp",
    "/doctor",
    "/p",
    "/subscriptions"
];

export function SaasApp({
                                    backendFirebaseApp
                                }: {
    backendFirebaseApp: FirebaseApp,
}) {

    const analytics = useSaasAnalytics();
    const navigate = useNavigate();
    const location = useLocation();

    const adminRequiredForPath = adminPaths.some(p => location.pathname.startsWith(p));
    const hideProjectSelect = hideProjectSelectorPaths.some(p => location.pathname.startsWith(p));

    const fireCMSBackend: FireCMSBackend = useBuildFireCMSBackend({
        backendApiHost: import.meta.env.VITE_API_SERVER,
        backendFirebaseApp,
        onUserChange: (user) => {
            analytics.setUserId(user?.uid ?? null);
            Sentry.setUser(user
                ? {
                    id: user.uid,
                    email: user.email ?? ""
                }
                : null);
        }
    });

    const selectProject = useCallback((projectId?: string) => {
        navigate(projectId ? `p/${projectId}` : "/");
    }, [navigate]);

    const onNewProject = useCallback(() => {
        navigate("new");
    }, [navigate]);

    const goToDoctor = useCallback((projectId: string) => {
        navigate("doctor/" + projectId);
    }, [navigate]);

    const goToSubscriptions = () => navigate("subscriptions");

    const saasController: SaasClientController = {
        selectProject,
        onNewProject,
        goToSubscriptions,
        goToDoctor,
        fireCMSBackend
    };

    const loading = fireCMSBackend.authLoading || fireCMSBackend.availableProjectsLoading;

    let component;
    if (loading) {
        component = <CircularProgressCenter/>;
    } else if (!fireCMSBackend.user) {

        component = <CenteredView maxWidth={"lg"}>
            <FireCMSCloudLoginView fireCMSBackend={fireCMSBackend}
                                   includeGoogleAdminScopes={adminRequiredForPath}
                                   includeLogo={true}
                                   includeGoogleDisclosure={false}
                                   includeTermsAndNewsLetter={true}/>
        </CenteredView>;

    } else if (backendFirebaseApp) {

        component = (
            <Routes>

                <Route path="p/:projectId/*"
                       element={
                           <SaasCMSAppClient
                               fireCMSBackend={fireCMSBackend}
                               onAnalyticsEvent={(eventName, projectId, params) => {
                                   console.debug("Logging event", eventName, projectId, params);
                                   const eventParams = {
                                       ...(params ?? {}),
                                       projectId
                                   };
                                   analytics.logEvent("p/" + eventName, eventParams);
                               }}/>}/>

                <Route path={"*"}
                       element={
                           <SaasScaffold
                               includeProjectSelect={!hideProjectSelect && (fireCMSBackend.availableProjectIds ?? [])?.length > 0}>
                               <Routes>
                                   <Route path="/"
                                          element={
                                              <SaasMainPage
                                                  selectProject={selectProject}
                                                  fireCMSBackend={fireCMSBackend}
                                                  onNewProject={onNewProject}
                                                  redirectToProject={true}
                                                  goToSubscriptions={goToSubscriptions}/>}/>
                                   <Route path="main"
                                          element={
                                              <SaasMainPage
                                                  selectProject={selectProject}
                                                  fireCMSBackend={fireCMSBackend}
                                                  onNewProject={onNewProject}
                                                  redirectToProject={false}
                                                  goToSubscriptions={goToSubscriptions}/>}/>
                                   <Route path="debug"
                                          element={
                                              <SassDebugView
                                                  selectProject={selectProject}
                                                  fireCMSBackend={fireCMSBackend}
                                                  onNewProject={onNewProject}/>}/>

                                   <Route path="cli"
                                          element={<FireCMSCLIView
                                              fireCMSBackend={fireCMSBackend}/>}/>

                                   <Route path="new/*"
                                          element={
                                              <NewFireCMSProjectStart
                                                  fireCMSBackend={fireCMSBackend}
                                                  onProjectCreated={(projectId) => {
                                                      analytics.logProjectCreationExistingProjectSelectedSuccess(projectId);
                                                      selectProject(projectId);
                                                  }}/>}/>

                                   <Route path="gcp"
                                          element={
                                              <NewGoogleCloudProjectFlow
                                                  fireCMSBackend={fireCMSBackend}
                                                  onProjectCreated={(projectId) => {
                                                      analytics.logProjectCreationNewProjectSelectedSuccess(projectId);
                                                      selectProject(projectId);
                                                  }}/>}/>

                                   <Route path="doctor/:projectId"
                                          element={
                                              <FireCMSDoctorProjectPage
                                                  fireCMSBackend={fireCMSBackend}/>}/>

                                   <Route path="doctor"
                                          element={
                                              <FireCMSDoctorListPage
                                                  goToDoctor={goToDoctor}
                                                  fireCMSBackend={fireCMSBackend}/>}/>

                                   <Route path="subscriptions"
                                          element={
                                              <UserSubscriptions
                                                  fireCMSBackend={fireCMSBackend}/>}/>

                                   {/*<Route path="subscriptions"*/}
                                   {/*       element={*/}
                                   {/*           <SelfHostedSubscriptions*/}
                                   {/*               fireCMSBackend={fireCMSBackend}/>}/>*/}

                                   <Route path={"*"}
                                          element={
                                              <CenteredView>
                                                  <NotFoundPage/>
                                              </CenteredView>}/>
                               </Routes>
                           </SaasScaffold>
                       }
                />

            </Routes>
        );
    }

    if (!backendFirebaseApp) {
        return <CircularProgressCenter text={"Initializing FireCMS"}/>;
    }

    return (
        <SaasClientContext.Provider value={saasController}>
            <FireCMSBackEndProvider {...fireCMSBackend}>
                <SnackbarProvider>
                    {component}
                </SnackbarProvider>
            </FireCMSBackEndProvider>
        </SaasClientContext.Provider>
    );

}
