import { FireCMSAppConfig } from "@firecms/cloud";

// @ts-ignore
// eslint-disable-next-line camelcase
import { __federation_method_getRemote, __federation_method_setRemote } from "__federation__";

/**
 * This function is in charge of retrieving a remote url and build a module
 * @param url
 */
export function getRemoteConfig(url: string): Promise<FireCMSAppConfig> {

    // host must look like: https:// + domain +  from the given url
    const host = new URL(url).origin;
    clearClientCss(host);
    __federation_method_setRemote("myRemote", {
        url,
        format: "esm"
    })
    return __federation_method_getRemote("myRemote", "./config").then((res: any) => {
        moveCssToTopOfHead(host);
        console.debug("Remote config loaded", res)
        return res.default ?? res;
    });
}

// remove all the css links starting with the host
export function clearClientCss(host: string) {
    const links = document.querySelectorAll("link[rel=stylesheet]");
    links.forEach(link => {
        if ("href" in link && typeof link.href === "string" && link.href.startsWith(host)) {
            link.remove();
        }
    });
}


// find the first css link related to host and move it to the top of the head
export function moveCssToTopOfHead(host: string) {
    const links = document.querySelectorAll("link[rel=stylesheet]");
    links.forEach(link => {
        if ("href" in link && typeof link.href === "string" && link.href.startsWith(host)) {
            document.head.insertBefore(link, document.head.firstChild);
        }
    });
}

