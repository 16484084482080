import React, { useEffect, useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DoneIcon,
    LoadingButton,
    TextField,
    Typography,
} from "@firecms/ui";
import { CircularProgressCenter, FieldCaption, useSnackbarController } from "@firecms/core";
import { Formex, useCreateFormex } from "@firecms/formex";
import {
    ProductPrice,
    ProductWithPrices,
    ProLicense,
    SubscriptionPriceSelect,
    useFireCMSBackend,
    useLicensesForUserController,
    useSubscriptionsForUserController
} from "@firecms/cloud";
import { TierPricingTable } from "./TierPricingTable";
import { createLicenseSubscription } from "./common";
import { generateApiKey } from "./api_keys";

export function LicenseDetailsForm({
                                       open,
                                       license,
                                       handleClose
                                   }: {
    open: boolean,
    license?: ProLicense,
    handleClose: () => void
}) {

    const snackbarController = useSnackbarController();
    const isNewProLicense = !license;

    const {
        products,
        productsLoading,
        subscribe
    } = useSubscriptionsForUserController();

    const product: ProductWithPrices | undefined = products?.find(p => p.metadata?.type === "pro");

    const [selectedPrice, setSelectedPrice] = useState<ProductPrice>();

    const productPrices: ProductPrice[] | undefined = product?.prices;
    useEffect(() => {
        if (productPrices && productPrices.length > 0) {
            setSelectedPrice(productPrices[0]);
        }
    }, [productPrices]);

    if (product && product?.metadata.type !== "pro" && product?.metadata.type !== "cloud_plus") {
        throw new Error("Error: Unmapped product type in ProductView");
    }

    const largePriceLabel = false;

    const [linkLoading, setLinkLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const { user } = useFireCMSBackend();
    const {
        createLicense,
    } = useLicensesForUserController();

    const formex = useCreateFormex({
        initialValues: license ?? {
            licensed_users: 5,
            archived: false,
            api_key: generateApiKey(),
            firebase_project_ids: [],
            created_at: new Date(),
            created_by: user?.uid ?? "",
        } as Partial<ProLicense>,
        // validation: (values) => {
        //     return ProLicenseYupSchema.validate(values, { abortEarly: false })
        //         .then(() => {
        //             return {};
        //         }).catch((e) => {
        //             return e.inner.reduce((acc: any, error: any) => {
        //                 acc[error.path] = error.message;
        //                 return acc;
        //             }, {});
        //         });
        // },
        onSubmit: (license: Partial<ProLicense>, formexController) => {

            async function navigateToStripe(updatedLicense: ProLicense) {

                if (!selectedPrice) {
                    throw new Error("No price selected");
                }
                setLinkLoading(true);
                await createLicenseSubscription(subscribe, updatedLicense, selectedPrice)
                    .catch((e) => setError(e))
                    .finally(() => setLinkLoading(false));
            }

            if (!isNewProLicense) {
                navigateToStripe(license as ProLicense).then(() => {
                    handleClose();
                });
                return Promise.resolve();
            }

            return createLicense(license)
                .then(async (updatedLicense) => {

                    if (isNewProLicense) {
                        await navigateToStripe(updatedLicense);
                    }
                    handleClose();
                    formexController.resetForm({
                        values: license
                    });
                }).catch((e) => {
                    console.error(e);
                    snackbarController.open({
                        type: "error",
                        message: e.message
                    });
                });
        }
    });

    const {
        isSubmitting,
        touched,
        handleChange,
        values,
        errors,
        setFieldValue,
        dirty,
        handleSubmit,
        submitCount
    } = formex;

    const hasError = Boolean(errors.licensed_users);

    if (productsLoading) {
        return <CircularProgressCenter/>
    }

    return (
        <Dialog
            open={open}
            onOpenChange={(open) => !open ? handleClose() : undefined}
            maxWidth={"4xl"}
        >
            <Formex value={formex}>
                <form
                    onSubmit={handleSubmit}
                    autoComplete={"off"}
                    noValidate
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        height: "100%"
                    }}>
                    <DialogContent className="h-full flex-grow">
                        <div
                            className="flex flex-row pt-4 pb-4">
                            <Typography variant={"h4"}
                                        className="flex-grow">
                                Buy new subscription
                            </Typography>
                        </div>

                        <div className={"flex flex-col gap-8"}>

                            <div>
                                <TextField
                                    name="licensed_users"
                                    required
                                    error={submitCount > 0 && Boolean(errors.licensed_users)}
                                    value={values.licensed_users}
                                    onChange={handleChange}
                                    type={"number"}
                                    label="Licensed users"
                                />
                                <FieldCaption>
                                    {submitCount > 0 && Boolean(errors.licensed_users) ? errors.licensed_users : "How many users will be able to use this license?"}
                                </FieldCaption>
                            </div>

                            {isNewProLicense && <div className={"flex flex-row gap-2 items-center px-2"}>
                                <Typography variant={"label"}>Currency:</Typography>
                                <SubscriptionPriceSelect productPrices={productPrices}
                                                                                     fullWidth={false}
                                                                                     setSelectedPrice={setSelectedPrice}
                                                                                     largePriceLabel={largePriceLabel}
                                                                                     selectedPrice={selectedPrice}/></div>}

                            {selectedPrice && <TierPricingTable price={selectedPrice}/>}

                        </div>

                    </DialogContent>

                    <DialogActions>

                        <Button variant={"text"}
                                onClick={() => {
                                    handleClose();
                                }}>
                            Cancel
                        </Button>

                        <LoadingButton
                            variant="filled"
                            color="primary"
                            type="submit"
                            disabled={hasError}
                            loading={isSubmitting || linkLoading}
                            startIcon={<DoneIcon/>}
                        >
                            {isNewProLicense ? "Create license" : "Add subscription"}
                        </LoadingButton>

                    </DialogActions>
                </form>
            </Formex>

        </Dialog>
    );
}
